import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"



const IndexPage = () => (
    <Layout>
        <SEO title="Home"/>
        <h1>Welcome!</h1>
        <div style={{maxWidth: `900px`, overflow: "auto"}}>
            <div style={{display: "inline-block", maxWidth: `620px`, float:"right"}}>
                <p>I graduated from UC San Diego as a Regents' Scholar with a B.S. in Mathematics-Computer Science in 2019. I currently
                    work at <a href="https://snapiot.com/">snapIoT</a> as a Software Engineer, focusing on Mobile Development.
                    In my free time, I enjoy running, hiking, and tending my small (but growing!) garden.</p>
                <p>You can reach me by <a href="mailto:alisabethrogers@gmail.com">email</a> or find me on <a href="https://www.linkedin.com/in/alisabeth-rogers/">LinkedIn</a>.</p>
            </div>
            <div style={{width: `250px`, padding: "0px", margin: "0px"}}  >
                <Image style={{maxWidth: `250px`}}/>
            </div>

        </div>
    </Layout>
)

export default IndexPage
